import React from 'react'
import { MDBCol, MDBCard, MDBCardBody, MDBCardText, } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'

class Card extends React.Component {
  render() {
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-card d-flex">
        <MDBCard className="flex-fill">
          <GatsbyImage
            top
            image={this.props.image}
            overlay="white-slight"
            hover
            waves
            alt={this.props.alt}
            className="rounded-top drop-shadow"
          />
          <MDBCardBody>
            <div className="card-title" style={{ minHeight: this.props.height, maxHeight: this.props.height, }} >
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2">
                {this.props.title}
              </h3>
              <p className="font-alt font-w-400 letter-spacing-1 text-xs-medium text-medium mt-2">
                {this.props.subtitle}
              </p>
            </div>
            <hr />
            <MDBCardText className={`font-w-400 mt-2 mb-2 ` + this.props.descriptionClass ? this.props.descriptionClass : 'text-medium'}>
              {this.props.link.substring(0,4) != 'http' ? (
                <Link to={this.props.link} className="effect">
                  {this.props.description}
                </Link>
              ) : (
                <a href={this.props.link} className="effect" target="_blank" rel="noreferrer">
                  {this.props.description}
                </a>
              )}
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default Card